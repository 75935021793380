export const SET_PLAYER = 'SET_PLAYER';
export const SET_PLAY_LIST = 'SET_PLAY_LIST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_ERROR = 'GET_CONTENT_ERROR';
export const SET_POSITION = 'SET_POSITION';
export const SET_POSITION_FULL = 'SET_POSITION_FULL';
export const GET_POSITION = 'GET_POSITION';
export const SET_PROGRESS = 'SET_PROGRESS';
export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_ERROR = 'DELETE_CONTENT_ERROR';
export const DELETE_STREAM_REQUEST = 'DELETE_STREAM_REQUEST';
export const DELETE_STREAM_SUCCESS = 'DELETE_STREAM_SUCCESS';
export const DELETE_STREAM_ERROR = 'DELETE_STREAM_ERROR';
export const RENAME_CONTENT_REQUEST = 'RENAME_CONTENT_REQUEST';
export const RENAME_CONTENT_SUCCESS = 'RENAME_CONTENT_SUCCESS';
export const RENAME_CONTENT_ERROR = 'RENAME_CONTENT_ERROR';
export const GET_PLAYERS_REQUEST = 'GET_PLAYERS_REQUEST';
export const GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS';
export const GET_PLAYERS_ERROR = 'GET_PLAYERS_ERROR';
export const GET_PLAYER_ERROR = 'GET_PLAYER_ERROR';
export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST';
export const GET_PLAYER_SUCCESS = 'GET_PLAYE_SUCCESS';
export const SET_PLAYER_REQUEST = 'SET_PLAYER_REQUEST';
export const SET_PLAYER_SUCCESS = 'SET_PLAYER_SUCCESS';
export const SET_PLAYER_ERROR = 'SET_PLAYER_ERROR';
export const DELETE_PLAYER_REQUEST = 'DELETE_PLAYER_REQUEST';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_ERROR = 'DELETE_PLAYER_ERROR';
export const GET_PLAYLISTS_REQUEST = 'GET_PLAYLISTS_REQUEST';
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const GET_PLAYLISTS_ERROR = 'GET_PLAYLISTS_ERROR';
export const EDIT_PLAYLISTS_REQUEST = 'EDIT_PLAYLISTS_REQUEST';
export const EDIT_PLAYLISTS_SUCCESS = 'EDIT_PLAYLISTS_SUCCESS';
export const EDIT_PLAYLISTS_ERROR = 'EDIT_PLAYLISTS_ERROR';
export const CREATE_PLAYLISTS_REQUEST = 'CREATE_PLAYLISTS_REQUEST';
export const CREATE_PLAYLISTS_SUCCESS = 'CREATE_PLAYLISTS_SUCCESS';
export const CREATE_PLAYLISTS_ERROR = 'CREATE_PLAYLISTS_ERROR';
export const DELETE_PLAYLISTS_REQUEST = 'DELETE_PLAYLISTS_REQUEST';
export const DELETE_PLAYLISTS_SUCCESS = 'DELETE_PLAYLISTS_SUCCESS';
export const DELETE_PLAYLISTS_ERROR = 'DELETE_PLAYLISTS_ERROR';
export const ADD_PLAYLIST_TO_PLAYER_REQUEST = 'ADD_PLAYLIST_TO_PLAYER_REQUEST';
export const ADD_PLAYLIST_TO_PLAYER_SUCCESS = 'ADD_PLAYLIST_TO_PLAYER_SUCCESS';
export const ADD_PLAYLIST_TO_PLAYER_ERROR = 'ADD_PLAYLIST_TO_PLAYER_ERROR';
export const SET_UPLOAD_FILES = "SET_UPLOAD_FILES";
export const CLEAR_PAGE_ERROR = "CLEAR_PAGE_ERROR";
export const CHECK_PACS_PLAYLIST_REQUEST = 'CHECK_PACS_PLAYLIST_REQUEST';
export const CHECK_PACS_PLAYLIST_SUCCESS = 'CHECK_PACS_PLAYLIST_SUCCESS';
export const CHECK_PACS_PLAYLIST_ERROR = 'CHECK_PACS_PLAYLIST_ERROR';
export const GET_PACS_PLAYLISTS_REQUEST = 'GET_PACS_PLAYLISTS_REQUEST';
export const GET_PACS_PLAYLISTS_SUCCESS = 'GET_PACS_PLAYLISTS_SUCCESS';
export const GET_PACS_PLAYLISTS_ERROR = 'GET_PACS_PLAYLISTS_ERROR';
export const SET_PACS_PLAYLISTS_REQUEST = 'SET_PACS_PLAYLISTS_REQUEST';
export const SET_PACS_PLAYLISTS_SUCCESS = 'SET_PACS_PLAYLISTS_SUCCESS';
export const SET_PACS_PLAYLISTS_ERROR = 'SET_PACS_PLAYLISTS_ERROR';
export const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_ERROR = 'GET_LOGS_ERROR';
export const GET_LOG_IDS_REQUEST = 'GET_LOG_IDS_REQUEST';
export const GET_LOG_IDS_SUCCESS = 'GET_LOG_IDS_SUCCESS';
export const GET_LOG_IDS_ERROR = 'GET_LOG_IDS_ERROR';
export const UPDATE_PLAYER_APP = 'UPDATE_PLAYER_APP';
export const SET_FULL_PLAYER_INFO = 'SET_FULL_PLAYER_INFO';
export const GET_FULL_PLAYER_INFO = 'GET_FULL_PLAYER_INFO';
export const RELOAD_PLAYER = 'RELOAD_PLAYER';
export const SHOW_ERROR = 'SHOW_ERROR';
export const REMOVE_SNACK = 'REMOVE_SNACK';
export const ADD_SNACK = 'ADD_SNACK';
export const REMOVE_PROGRESS = 'REMOVE_PROGRESS';
export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const CHANGE_MODES_REQUEST = 'CHANGE_MODES_REQUEST';
export const CHANGE_MODES_SUCCES = 'CHANGE_MODES_SUCCES';
export const CHANGE_MODES_ERROR = 'CHANGE_MODES_ERROR';
export const PLAY_NOW_FILE = 'PLAY_NOW_FILE';
export const DELETE_PLAYER_ADMIN_REQUEST = 'DELETE_PLAYER_ADMIN_REQUEST';
export const DELETE_PLAYER_ADMIN_SUCCESS = 'DELETE_PLAYER_ADMIN_SUCCESS';
export const DELETE_PLAYER_ADMIN_ERROR = 'DELETE_PLAYER_ADMIN_ERROR';