import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { prettiNum } from '../../services/utils';
import * as userActions from '../../store/actions/UserActions';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
    container: {
        display: 'flex',
        height: '60px',
        backgroundColor: '#282c34e0',
        flexDirection: 'column'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'lightgray',
        fontSize: '12px'
    },
    columnsmall: {
        width: '80px',
        display: 'flex',
        paddingTop: '15px'
    },
    columnbig: {
        width: '200px',
        display: 'flex',
        paddingTop: '15px',
        textAlign: 'justify',
    },
    columnbigWP: {
        width: '200px',
        display: 'flex',
        textAlign: 'justify',
    },
    role: {
        paddingRight: '10px',
        paddingTop: '15px'
    }
});

const User = ({ classes, usersdata, handleUserChange }) => {

    // Получаем данные из Redux store
    const { user, page, language } = useSelector(state => ({
        user: state.user,
        page: state.page,
        language: state.language
    }));

    // Обработчик изменения роли
    const handleChangeRole = useCallback((event) => {
        const newRole = event.target.value;
        handleUserChange('role', usersdata.id, usersdata.username, newRole);
    }, [usersdata.id, usersdata.name]);

    const handleChangeMoney = useCallback((event) => {
        const value = event.target.value;
        const keyCode = event.code
        const numericValue = value.replace(/\D/g, '');
        if (keyCode === "Enter" && numericValue <= 1000000) {
            console.log("CHANGE MONEY TO: " + numericValue)
            handleUserChange('balance', usersdata.id, usersdata.username, numericValue);
        }
        // console.log("CHANGE MONEY TO: " + newMoney
        // handleUserRoleChange(usersdata.id, usersdata.username, newMoney);
    }, [usersdata.id, usersdata.name]);

    // Мемоизированные значения
    const roles = useMemo(() => ['user', 'admin', 'corp'], []);
    const balanceValue = useMemo(() => `${prettiNum(usersdata.balance)} rub.`, [usersdata.balance]);

    return (
        <div style={{ padding: '5px' }}>
            <Card>
                <div className={classes.container}>
                    <div className={classes.component}>
                        <div className={classes.columnsmall}>id: {usersdata.id}</div>
                        <div className={classes.columnbig}>name: {usersdata.username}</div>
                        <div className={classes.columnbigWP}>
                            <span className={classes.role}>Balance: </span>
                            {/* <TextField
                                defaultValue={balanceValue}
                                sx={{ m: 1, width: '25ch' }}
                            /> */}
                            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                <Input
                                    id="amount"
                                    endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'amount',
                                        style: {
                                            textAlign: 'center'
                                        },
                                        maxLength: 7,
                                    }}
                                    defaultValue={usersdata.balance}
                                    // onChange={handleChangeMoney}
                                    onKeyPress={(event) => {
                                        // Разрешаем ввод только цифр
                                        if (!/[0-9]/.test(event.key) && event.key !== "Enter") {
                                            event.preventDefault();
                                        } else {
                                            handleChangeMoney(event)
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className={classes.columnbig}></div>
                        <div className={classes.columnbigWP}>
                            <span className={classes.role}>Role: </span>
                            <FormControl className={classes.formControl}>
                                <Select
                                    style={{ color: 'gray' }}
                                    native
                                    value={usersdata.role}
                                    onChange={handleChangeRole}
                                    inputProps={{ name: 'own', id: 'own' }}
                                >
                                    {roles.map((v, key) => (
                                        <option key={key} value={v}>{v}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {usersdata.errormessage === ' ' && (
                            <div className={classes.columnbig} style={{ fontSize: '12px' }}>
                                Error: {usersdata.errormessage}
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withStyles(styles)(User);