import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Singin from './User/SignIn';
import AlertDialog from './Other/AlertDialog';
import Header from '../components/Header/AppBarZerro';
import Footer from './Footer/Footer';
import Players from './Players/Players';
import * as pageActions from '../store/actions/PageActions';
import * as userActions from '../store/actions/UserActions';
import * as network from '../services';
import '../styles/App.css';

export let socket;

const darkTheme = createMuiTheme({ palette: { type: "dark" } });
const lightTheme = createMuiTheme({ palette: { type: "light" } });

const App = () => {



  const [isLoading, setIsLoading] = useState(false);
  const [alertUser, setAlertUser] = useState(false);
  const [topLevelDark, setTopLevelDark] = useState(true);

  const dispatch = useDispatch();
  const { token, role, error, id } = useSelector(state => state.user); // Вынесли useSelector на верхний уровень

  useEffect(() => {
    if (!isLoading && token) {
      console.log('APP / data is loading');
      dispatch(pageActions.getContent(token));
      dispatch(userActions.getUserData(token));
      dispatch(pageActions.getPlaylists(token));
      dispatch(pageActions.getPlayers(token));
      dispatch(userActions.getLogo(token));
      dispatch(userActions.getusersdata(token));
      dispatch(userActions.getModes(token));
      dispatch(pageActions.getPacsPlaylists(token));
      socket = network.socket.connect(token, id); // Используем id, полученный из useSelector
      setIsLoading(true);
    }
  }, [isLoading, token, dispatch, id]);

  useEffect(() => {
    if (error && !alertUser) {
      setAlertUser(true);
    }
  }, [error, alertUser]);

  const handleLogOut = () => {
    dispatch(userActions.logOut(token));
    setTimeout(() => {
      localStorage.clear();
      socket.disconnect();
    }, 500);
  };

  const handleAlertOk = () => {
    dispatch(pageActions.clearPageError());
    dispatch(userActions.clearUserError());
    setAlertUser(false);
  };

  const getUserAuthorization = (login, password) => {
    dispatch(userActions.getUserAuthorization(login, password))
  }

  const handleChangeTheme = () => {
    setTopLevelDark(prev => !prev);
  };

  return (
    <MuiThemeProvider theme={topLevelDark ? darkTheme : lightTheme}>
      {token ? (
        <div className="App" style={{ height: window.innerHeight }}>
          <header>
            <Header logout={handleLogOut} token={token} />
            {role === "admin" ? (
              <Players handleChangeTheme={handleChangeTheme} />
            ) : (
              <div>No Permission to enter</div>
            )}
            <Footer />
            <AlertDialog
              open={alertUser}
              alert="Error!"
              message={error}
              handleAgree={handleAlertOk}
            />
          </header>
        </div>
      ) : (
        <div className="App">
          <header className="App-header">
            <Singin getUserAuthorization={getUserAuthorization} />
            <AlertDialog
              open={alertUser}
              alert="Error!"
              message={error}
              handleAgree={handleAlertOk}
            />
          </header>
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default App;