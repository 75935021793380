export const ROOT_API_URL = 'https://admin.revip.org';
export const ROOT_API_WS = 'https://www.revip.org';

export const PATHS = {
  authorization: '/api/auth/login',
  registration: '/register',
  registrationListener: '/registerlistener',
  content: '/api/content/ui',
  sio: '/admin',
  deletecontent: '/api/content/delete',
  deletestream: '/api/content/deletestream',
  renamecontent: '/api/content/rename',
  user: '/api/auth/user',
  players: '/admin/getplayersdata',
  getusersdata: '/admin/getusersdata',
  deletePlayer: '/api/players/deleteplayer',
  createplaylist: '/api/ui/createplaylist',
  editplaylist: '/api/ui/editplaylist',
  playlists: '/api/ui/playlistui',
  deleteplaylist: '/api/ui/deleteplaylist',
  addplaylisttoplayer: '/api/ui/addplaylisttoplayer',
  getPreviewJpeg: '/api/ui/getpreviewjpeg',
  uploadLogo: '/api/ui/uploadlogo',
  downloads: '/api/ui/downloads',
  checkpacsplaylist: '/utils/checkpacsplaylist',
  pacsplaylist: '/api/ui/pacsplaylist',
  setpacsplaylist: '/api/ui/setpacsplaylist',
  getLogs: '/api/players/getlog',
  getLogIds: '/api/players/getlogids',
  changepassword: '/api/auth/changepassword',
  getmodes: '/api/auth/getmode',
  changemode: '/api/players/changemode',
  sendconfirmmessage: '/api/auth/sendconfirmmessage',
  setlistenids: '/api/auth/setlistenid',
  deletelistener: '/api/auth/deletelistener',
  getpromotioncode: '/api/auth/getpromotioncode',
  changeusettype: '/admin/changeusertype',
  changeuserbalance: '/admin/addmoney',
  deleteplayeradmin: '/admin/deleteplayer'
};