import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PlayerUI from '../Players/PlayerUI';
import PlayerHead from '../Players/PlayerHead';
import Users from '../User/users';
import AlertDialog from '../Other/AlertDialog';
import * as pageActions from '../../store/actions/PageActions'

const styles = theme => ({
    root: {
        display: 'block',
        backgroundColor: "#282c34",
        overflow: "scroll",
        color: "white",
        '& .table_header_red': {
            color: 'red',
        },
        '& .booleanCell': {
            color: 'red',
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: 'red'
        }
    },
    MuiDataGridBooleanCell: {
        color: 'white',
    },
    icons: {
        display: 'flex',
        alignItems: 'center'
    }
});

const Players = ({ classes, handleChangeTheme }) => {
    const [checkOnline, setCheckOnline] = useState(false);
    const [own, setOwn] = useState('*');
    const [pageOpen, setPageOpen] = useState('players');
    const [alertPlayerCahnge, setAlertPlayerCahnge] = useState(false);
    const [playerChangeType, setPlayerChangeType] = useState('');
    const [playerUserId, setPlayerUserId] = useState('');
    const [playerUserName, setPlayerUserName] = useState('');
    const [playerData, setPlayerData] = useState({});
    const [alertMessage, setAlertMessage] = useState('');
    const { players } = useSelector(state => state.page);
    const { usersdata, token } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const handleCheckOnline = () => setCheckOnline(prev => !prev);
    const handleChange = name => event => setOwn(event.target.value);
    const handleChangePage = page => setPageOpen(page);

    const filteredPlayers = players
        .filter(player => checkOnline ? player.online : true)
        .filter(player => String(player.own) === String(own));

    const handlePlayerChange = (type, userId, userName, data) => {
        setPlayerChangeType(type);
        setPlayerUserId(userId);
        setPlayerUserName(userName);
        setPlayerData(data);
        switch (playerChangeType) {
            case 'delete':
                setAlertMessage(`Please confirm DELETE player!!! USER: ${userName}  ID: ${userId} PlayerID: ${data.id} PlayerName: ${data.name}`)
                setAlertPlayerCahnge(true);
                break;

            default:
                break;
        }

    }

    const handlePlayerChangeConfirm = () => {
        switch (playerChangeType) {
            case 'delete':
                const dataD = { uid: playerUserId, pid: playerData.id }
                dispatch(pageActions.deletePlayerAdmin(token, dataD))
                break;

            default:
                break;
        }
        setAlertPlayerCahnge(false)
    }

    return (
        <div style={{ height: window.innerHeight - 35 }} className={classes.root}>
            <PlayerHead
                handleCheckOnline={handleCheckOnline}
                state={{ checkOnline, own, pageOpen }}
                handleChange={handleChange}
                handleChangeTheme={handleChangeTheme}
                handleChangePage={handleChangePage}
                pageOpen={pageOpen}
            />

            {usersdata !== undefined && own !== 0 ?
                pageOpen === 'players' ?
                    filteredPlayers.map((player, index) => {
                        const user = usersdata.find(usr => usr.id === player.own);
                        return <PlayerUI player={player} key={index} usr={user} handlePlayerChange={handlePlayerChange} />;
                    })
                    :
                    <Users own={own} />
                : null
            }

            <AlertDialog
                open={alertPlayerCahnge}
                handleClose={() => setAlertPlayerCahnge(false)}
                handleAgree={handlePlayerChangeConfirm}
                alert={'Warning'}
                message={alertMessage}
            />
        </div>
    );
};

export default withStyles(styles)(Players);