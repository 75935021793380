import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import User from './user';
import AlertDialog from '../Other/AlertDialog';
import * as userActions from '../../store/actions/UserActions';
import PropTypes from 'prop-types';


const styles = theme => ({
    root: {
        display: 'block',
        backgroundColor: "#282c34",
        overflow: "scroll",
        color: "white",
        '& .table_header_red': {
            color: 'red',
        },
        '& .booleanCell': {
            color: 'red',
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: 'red'
        }
    },
    MuiDataGridBooleanCell: {
        color: 'white',
    },
    icons: {
        display: 'flex',
        alignItems: 'center'
    }
});

const Users = ({ own, classes }) => {
    const [confirmAlert, setConfirmAlert] = useState(false);
    const [newRole, setNewRole] = useState(null);
    const [userCahngeId, setUserChangeId] = useState(null);
    const [userCahngeName, setUserChangeName] = useState(null);
    const [message, setMessage] = useState(null)
    const [changeType, setChangeType] = useState(null);
    const [changeData, setChangeData] = useState(null);

    const { usersdata, token } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const handleChangeRole = useCallback(() => {

        setNewRole(newRole);
    }, []);

    const handleUserChange = (type, userId, userName, data) => {
        setUserChangeId(userId);
        setUserChangeName(userName);
        setChangeType(type);
        setChangeData(data);
        console.log('>>>>>> handleUserChange', type, userId, userName, data)


        switch (type) {
            case 'role':
                setMessage('Please confirm change user role! \n USER: ' + userName + ' ID: ' + userId + ' NEW ROLE: ' + data);
                setNewRole(data);
                setConfirmAlert(true);
                break;

            case 'balance':
                setMessage('Please confirm change user money! \n USER: ' + userName + ' ID: ' + userId + ' NEW MONEY: ' + data);
                setConfirmAlert(true);
                break;

            default:
                break;
        }


    };

    const handleChangeConfirm = () => {
        switch (changeType) {
            case 'role':
                const dataR = { uid: userCahngeId, type: changeData }
                dispatch(userActions.changeUserRole(token, dataR)); // Пример действия для изменения роли
                break;
            case 'balance':
                const dataB = { uid: userCahngeId, amount: changeData }
                dispatch(userActions.changeUserBalance(token, dataB)); // Пример действия для изменения денег
                break;
            default:
                break;
        }
        setConfirmAlert(false);

    };

    const handleChangeCancel = useCallback(() => {
        setConfirmAlert(false);
    }, []);

    const filteredUsers = useMemo(() => {
        return usersdata.filter(user => String(user.id) === String(own));
    }, [usersdata, own]);

    return (
        <div>
            {own !== 0 ?
                filteredUsers.map((user, index) => (
                    <User
                        key={user.id}
                        usersdata={user}
                        handleUserChange={handleUserChange}
                    />
                )) : null}
            <AlertDialog
                alert='Info'
                message={message}
                handleAgree={handleChangeConfirm}
                handleClose={handleChangeCancel}
                open={confirmAlert}
            />
        </div>
    );
};

Users.propTypes = {
    own: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);