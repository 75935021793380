import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

const UserSelect = ({ classes, players, handleChange, usersdata, sortById }) => {
    // const [filteredPlayers, setFilteredPlayers] = useState([]);

    // const ownToName = useCallback((own) => {
    //     if (!usersdata) return '';
    //     const user = usersdata.find(user => user.id === own);
    //     return user ? user.username : '';
    // }, [usersdata]);

    // useEffect(() => {
    //     const uniquePlayers = [];
    //     const seenOwn = new Set();

    //     players.forEach(player => {
    //         if (!seenOwn.has(player.own) && player.own !== 0) {
    //             seenOwn.add(player.own);
    //             uniquePlayers.push({
    //                 ...player,
    //                 username: ownToName(player.own)
    //             });
    //         }
    //     });

    const sortedUsers = usersdata.sort((a, b) => {
        if (sortById) {
            return a.id - b.id;
        } else {
            return a.username.toLowerCase().localeCompare(b.username.toLowerCase());
        }
    });

    //     setFilteredPlayers(sortedPlayers);
    // }, [players, usersdata, sortById, ownToName]);

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-user" style={{ color: 'gray' }}>
                    {sortById ? 'Select user ID' : 'Select user name'}
                </InputLabel>
                <Select
                    style={{ color: 'gray' }}
                    native
                    onChange={handleChange('own')}
                    inputProps={{
                        name: 'own',
                        id: 'own',
                    }}
                >
                    <option value={sortedUsers[0].id}>{sortById ? 'Select user ID' : 'Select user name'}</option>
                    {sortedUsers.map((user, key) => (
                        <option key={key} value={user.id}>
                            {sortById
                                ? `${user.id} (${user.username})`
                                : `${user.username} (${user.id})`
                            }
                        </option>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

UserSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    players: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    usersdata: PropTypes.array,
    sortById: PropTypes.bool,
};

export default withStyles(styles)(UserSelect);